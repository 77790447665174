import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select } from 'redux-saga/effects'

import routes, { Router } from '../../routes'
import ApiSagas from '../../redux/api/sagas'
import { selectors as AuthSelectors } from '../../redux/auth/redux'
import AuthSagas from '../../redux/auth/sagas'
import PaymentQueries from '../../graphql/query/Payment'
import { redirect } from '../../helpers/UrlHelpers'



export default class UserRegisterWelcomeSagas {

  // eslint-disable-next-line complexity
  static* init(ctx) {
    yield put(showLoading())
    const query = ctx.query

    const me = yield select(AuthSelectors.user)

    if (!me) {
      yield call(AuthSagas.redirectToLogin, ctx.res)
      return {}
    }

    const orderID = query?.orderID
    const STATUS = query?.STATUS
    const PAYID = query?.PAYID
    const NCERROR = query?.NCERROR
    const SHASIGN = query?.SHASIGN

    if (orderID && STATUS && PAYID && NCERROR && SHASIGN) {
      const res = yield call(ApiSagas.query, PaymentQueries.Submit3DSecureFormResult, {
        orderId: orderID,
        status: STATUS,
        payId: PAYID,
        ncError: NCERROR,
        shaSign: SHASIGN,
      })

      if (res.errors) {
        const link = `${Router.getRouteUrl(routes.userRegisterPremium)}?error=1&code=3dsecure`

        yield call(redirect, res, link, 303)
      }
    }

    yield all([
      yield put(hideLoading()),
    ])

    return {}
  }



  static* loop() {
    yield all([

    ])
  }

}
